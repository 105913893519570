import { useEffect } from "react";
import { getCHPYPapamByName, getLang } from "../utils/utils";
import i18n from "../i18n";
import { useLocalStorage } from "usehooks-ts";

export const cookieLangByLang = {
  ru: "ru_RU",
  en: "eb_US",
};

export default function useCommonHooks() {
  const chosenLang = getLang();
  const [lang, setLang] = useLocalStorage("lang", chosenLang);

  useEffect(() => {
    const campaignId = getCHPYPapamByName("campaign_id");
    if (!campaignId) return;

    localStorage.setItem("campaign_id", campaignId);
    document.cookie = encodeURIComponent("campaign_id") + "=" + encodeURIComponent(campaignId);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
    document.cookie =
      encodeURIComponent("language") + "=" + encodeURIComponent(cookieLangByLang[lang]);
  }, [lang]);

  useEffect(() => {
    const paramLang = getCHPYPapamByName("lang");
    const languages = [
      { title: "English", flag: "us", code: "en", country: "US" },
      { title: "Русский", flag: "ru", code: "ru", country: "RU" },
    ];

    if (paramLang && languages.length) {
      const currentLang = languages.find((el) => {
        return `${el.code}_${el.country}` === paramLang;
      });

      if (currentLang) {
        const code = currentLang.code;
        setLang(code);
        i18n.changeLanguage(code);
      }
    }
  }, []);
}
